<template>
  <div class="main-wrapper">
    <NavbarDashboard></NavbarDashboard>
    <SidebarDashboard></SidebarDashboard>
    <div class="main-content">
      <form @submit.prevent="onSubmit">
        <section class="section">
          <div class="d-flex justify-content-between align-items-center mb-3">
            <div class="">
              <h4 class="title">{{ labelPage }} Stock Opname</h4>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/data-opname-supply"
                      >Stock Opname</router-link
                    >
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    {{ labelPage }}
                  </li>
                </ol>
              </nav>
            </div>
            <div
              class="button-edit"
              v-show="validated == 1 && labelPage == 'Edit'"
            >
              <button class="btn-add" type="button" @click="editable">
                <img src="/img/icon-edit1.svg" alt="" style="width: 20px" />
                Edit
              </button>
            </div>
            <div v-show="validated != 1 || labelPage == 'Tambah'">
              <button class="btn btn-save" :disabled="isSubmit">
                <span class="fas fa-spinner fa-spin" v-if="isSubmit"></span>
                Simpan
              </button>
            </div>
          </div>

          <div class="card-table info-detail">
            <div class="row mt-3">
              <div class="col-6">
                <div class="title">{{ labelPage }} Stok Opname</div>
                <div class="sub-title">
                  {{ labelPage }} informasi yang diperlukan
                </div>
              </div>
              <div class="col-6 text-right">
                <div
                  v-if="
                    formData.kode &&
                    formData.status == 'aktif' &&
                    (checkAccess('persediaan') ||
                      checkAccess('persediaan', 'cru_stock_opname'))
                  "
                  class="btn btn-add"
                  @click="addHasil"
                >
                  Tambah Hasil
                </div>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label for="inputAddress2">Tanggal Perintah :</label>
                  <Datepicker
                    :disabled="validated == 1 && labelPage == 'Edit'"
                    :autoApply="true"
                    :closeOnScroll="true"
                    v-model="formData.tanggal_perintah"
                    placeholder="Tanggal Perintah"
                    :format="format"
                    :enableTimePicker="false"
                    locale="id-ID"
                    selectText="Pilih"
                    cancelText="Batal"
                    :class="{
                      'is-invalid': formError && formError.tanggal_perintah,
                    }"
                    @update:modelValue="changeDate($event, 'tanggal_perintah')"
                  ></Datepicker>
                </div>

                <div class="form-group">
                  <label class="" for="inlineFormInputGroupUsername"
                    >Penanggung Jawab :</label
                  >
                  <Select2
                    :disabled="validated == 1 && labelPage == 'Edit'"
                    v-model="formData.karyawan_perintah"
                    :options="OptionPersonResponsible"
                    placeholder="Pilih Penanggung Jawab"
                    @change="formChange('karyawan_perintah')"
                    @select="formChange('karyawan_perintah')"
                    :class="{
                      'is-invalid': formError && formError.karyawan_perintah,
                    }"
                  />
                </div>

                <div class="form-group">
                  <label for="inputAddress2">Departemen :</label>
                  <Select2
                    :disabled="validated == 1 && labelPage == 'Edit'"
                    v-model="formData.id_divisi"
                    :options="OptionDepartement"
                    placeholder="Pilih Departemen"
                    @change="formChange('id_divisi')"
                    @select="formChange('id_divisi')"
                    :class="{
                      'is-invalid': formError && formError.id_divisi,
                    }"
                  />
                </div>
              </div>

              <div class="col-6">
                <div class="form-group">
                  <label for="inputAddress2">Tanggal Pelaksanaan :</label>
                  <Datepicker
                    :disabled="validated == 1 && labelPage == 'Edit'"
                    :autoApply="true"
                    :closeOnScroll="true"
                    v-model="formData.tanggal_pelaksanaan"
                    placeholder="Tanggal Pelaksanaan"
                    :format="format"
                    :minDate="formData.tanggal_perintah"
                    :enableTimePicker="false"
                    locale="id-ID"
                    selectText="Pilih"
                    cancelText="Batal"
                    :class="{
                      'is-invalid': formError && formError.tanggal_pelaksanaan,
                    }"
                    @update:modelValue="
                      changeDate($event, 'tanggal_pelaksanaan')
                    "
                  ></Datepicker>
                </div>

                <div class="form-group">
                  <label class="" for="inlineFormInputGroupUsername"
                    >Pelaksana :</label
                  >
                  <Select2
                    :disabled="validated == 1 && labelPage == 'Edit'"
                    v-model="formData.karyawan_pelaksana"
                    :options="OptionEmployeImplementation"
                    placeholder="Pilih Pelaksana"
                    @change="formChange('karyawan_pelaksana')"
                    @select="formChange('karyawan_pelaksana')"
                    :class="{
                      'is-invalid': formError && formError.karyawan_pelaksana,
                    }"
                  />
                </div>

                <div class="form-group">
                  <label for="inputAddress2">Gudang :</label>
                  <Select2
                    :disabled="validated == 1 && labelPage == 'Edit'"
                    v-model="formData.id_gudang"
                    :options="OptionWerhouse"
                    placeholder="Pilih Gudang"
                    @change="formChange('id_gudang')"
                    @select="formChange('id_gudang')"
                    :class="{
                      'is-invalid': formError && formError.id_gudang,
                    }"
                  />
                </div>

                <div class="form-group">
                  <label class="" for="inlineFormInputGroupUsername"
                    >Keterangan :</label
                  >
                  <textarea
                    :disabled="validated == 1 && labelPage == 'Edit'"
                    type="text"
                    class="form-control"
                    v-model="formData.keterangan"
                    placeholder="Masukkan Keterangan"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </form>
    </div>
  </div>
</template>

<script>
import SidebarDashboard from "../../../../../components/Sidebar.vue";
import NavbarDashboard from "../../../../../components/Navbar.vue";
import Select2 from "vue3-select2-component";
import Datepicker from "vue3-date-time-picker";
import { ref } from "vue";

import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

// import $ from "jquery";
import {
  checkRules,
  cksClient,
  showAlert,
  checkModuleAccess,
} from "../../../../../helper";
import { get_ListDivisi } from "../../../../../actions/master";
import { get_GudangList } from "../../../../../actions/company/gudang";
import { get_KaryawanList } from "../../../../../actions/karyawan";
import {
  post_StockOpnameSave,
  get_StockOpnameDetail,
} from "../../../../../actions/barang/stockOpname";
import moment from "moment";
import "moment/locale/id";

// import TableLite from 'vue3-table-lite'
export default {
  name: "dataDepartement",
  components: {
    // FooterDashboard,
    SidebarDashboard,
    NavbarDashboard,
    Select2,
    Datepicker,
    // TableLite
  },

  data() {
    return {
      checkAccess: checkModuleAccess,
      validated: 1,
      OptionWerhouse: [],
      OptionDepartement: [],
      OptionPersonResponsible: [],
      OptionEmployeImplementation: [],
      isSubmit: false,
      id_company: cksClient().get("_account").id_company,
      labelPage: this.$route.params.kode ? "Edit" : "Tambah",
      accessDepartement: cksClient().get("_account").akses_departement,
      //FORMDATA
      formData: {
        kode: this.$route.params.kode ? this.$route.params.kode : "",
        id_company: cksClient().get("_account").id_company,
        id_divisi: "",
        id_gudang: "",
        tanggal_perintah: "",
        tanggal_pelaksanaan: "",
        karyawan_perintah: "",
        karyawan_pelaksana: "",
        keterangan: "",
        status: "aktif",
        persediaan: "true",
      },
      formError: [],
      rules: {
        id_divisi: {
          required: false,
        },
        id_gudang: {
          required: true,
        },
        tanggal_perintah: {
          required: true,
        },
        tanggal_pelaksanaan: {
          required: true,
        },
        karyawan_perintah: {
          required: true,
        },
        karyawan_pelaksana: {
          required: true,
        },
        keterangan: {
          required: false,
        },
        status: {
          required: false,
        },
      },
    };
  },

  setup() {
    const date = ref(new Date());

    // In case of a range picker, you'll receive [Date, Date]
    const format = (date) => {
      const month = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];
      const day = date.getDate();
      const monthText = month[date.getMonth()];
      const year = date.getFullYear();

      return `${day} ${monthText} ${year}`;
    };
    return {
      date,
      format,
    };
  },

  created() {
    if (this.labelPage == "Edit") {
      this.getData();
    } else if (this.accessDepartement) {
      var data = this.accessDepartement.split("|");
      this.formData.id_divisi = data[0];
    }
    this.getDivisi();
    this.getKaryawan();
    this.getGudang();
  },

  methods: {
    editable() {
      this.validated = 2;
    },
    addHasil() {
      this.$router.push({
        name: "StockResultSupply",
        params: {
          kode: this.formData.kode,
        },
      });
    },
    getData() {
      get_StockOpnameDetail(
        this.formData.kode,
        (res) => {
          var { data } = res;
          delete data.created_at;
          delete data.updated_at;
          data.tanggal_perintah = data.tanggal_perintah
            ? moment(data.tanggal_perintah).format("YYYY-MM-DD")
            : "";
          data.tanggal_pelaksanaan = data.tanggal_pelaksanaan
            ? moment(data.tanggal_pelaksanaan).format("YYYY-MM-DD")
            : "";
          this.formData = data;
        },
        () => {
          showAlert(this.$swal, {
            title: "GAGAL!",
            msg: "Data tidak ditemukan",
            showCancelButton: true,
            showConfirmButton: false,
            onCancel: () => {
              this.$router.push({
                name: "DataOpnameSupply",
              });
            },
          });
        }
      );
    },
    //MASTER
    getDivisi() {
      get_ListDivisi({ id_company: this.id_company }, (res) => {
        const { list } = res;
        this.OptionDepartement = [];
        for (const key in list) {
          if (Object.hasOwnProperty.call(list, key)) {
            const element = list[key];
            this.OptionDepartement.push(element);
          }
        }
      });
    },
    getGudang() {
      get_GudangList(
        {
          id_company: this.id_company,
          search: "aktif",
        },
        (res) => {
          var { list } = res;
          this.OptionWerhouse = [];
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              const element = list[key];
              this.OptionWerhouse.push({
                id: element.id,
                text: element.nama,
                alamat: element.alamat,
              });
            }
          }
        }
      );
    },
    getKaryawan() {
      var id_divisi =
        this.accessDepartement && this.formData.id_divisi
          ? this.formData.id_divisi
          : "";
      get_KaryawanList(
        {
          id_company: this.id_company,
          search: "aktif",
          id_divisi: id_divisi,
        },
        (res) => {
          var { list } = res;
          var data = [];
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              const element = list[key];
              data.push({
                id: element.id,
                text: element.nama,
              });
            }
          }
          this.OptionPersonResponsible = data;
          this.OptionEmployeImplementation = data;
        }
      );
    },
    changeDate(data, key) {
      this.formData[key] = moment(data).format("YYYY-MM-DD");
      this.formChange(key);
    },
    async formChange(key) {
      if (key == "id_divisi" && this.accessDepartement) {
        this.getKaryawan();
      }

      if (key == "tanggal_perintah" || key == "tanggal_pelaksanaan") {
        var first = this.formData.tanggal_perintah
          ? moment(this.formData.tanggal_perintah).unix()
          : "";
        var second = this.formData.tanggal_pelaksanaan
          ? moment(this.formData.tanggal_pelaksanaan).unix()
          : "";
        if (first && second && second < first) {
          this.formData.tanggal_pelaksanaan = "";
        }
      }

      if (this.rules[key]) {
        this.rules[key].changed = true;
      }
      var check = await checkRules(this.rules, this.formData);
      this.formError = check.error;
    },

    postData() {
      this.isSubmit = true;
      post_StockOpnameSave(
        this.formData,
        (res) => {
          this.isSubmit = false;
          var msg =
            res.response_code === 201
              ? "Perintah stock opname berhasil dicatat"
              : "Perintah stock opname berhasil diperbarui";
          showAlert(this.$swal, {
            title: "BERHASIL!",
            msg: msg,
            onSubmit: () => {
              this.$router.push({
                name: "DataOpnameSupply",
              });
            },
          });
        },
        () => {
          this.isSubmit = false;
          showAlert(this.$swal, {
            title: "GAGAL!",
            msg: "Terjadi kesalahan, silakan ulangi kembali",
            showCancelButton: true,
            showConfirmButton: false,
          });
        }
      );
    },

    async onSubmit() {
      for (const key in this.rules) {
        if (Object.hasOwnProperty.call(this.rules, key)) {
          this.rules[key].changed = true;
        }
      }
      var check = await checkRules(this.rules, this.formData);
      this.formError = check.error;
      if (check.success) {
        showAlert(this.$swal, {
          title: "PERHATIAN!",
          msg: this.formData.kode
            ? "Apakah anda yakin, Tindakan ini akan menyimpan semua perubahan yang anda lakukan?"
            : "Apakah anda yakin, semua data yang telah anda isikan akan tersimpan?",
          showCancelButton: true,
          cancelButtonText: "Batal",
          confirmButtonText: "Ya, Lanjutkan",
          onSubmit: () => {
            this.postData();
          },
        });
      }
    },
  },
};
</script>

<style scoped src="../../../../../assets/css/style.css"></style>

<style scoped src="../../../../../assets/css/custom.css"></style>

<style scoped>
.card .card-title {
  font-size: 18px;
  color: #000;
  font-weight: 500;
}

.info-detail .title {
  font-size: 18px;
  font-weight: 500;
}

.info-detail .sub-title {
  color: #c6c6c6;
  font-size: 12px;
  font-weight: 400;
  margin-top: 10px;
}
</style>
